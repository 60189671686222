<template>
	<Block type="data">
		<template #header>
			<h3 class="block-title">Transactions</h3>
		</template>
		<Datatable :options="options" />
	</Block>
</template>

<script lang="ts">
import Easy from '@/models/Easy'
import Qualification from '@/models/Qualification'
import RNPL from '@/models/RNPL'
import Vault from '@/models/Vault'
import ZERO from '@/models/ZERO'
import Block from '@/vue/components/Block.vue'
import { CellRender, Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator'

@Component({
	components: {
		Datatable,
		Block,
	},
})
class TransactionTable extends Vue {
	@Prop({ type: Object })
	readonly model!: Qualification | Easy | RNPL | Vault | ZERO

	get buttons() {
		let buttons: any[] = []
		buttons.push('refresh')
		buttons.push('trashed')

		return buttons
	}

	get columns() {
		let columns: any[] = []
		columns.push({
			data: 'id',
			orderable: true,
			name: 'id',
			title: 'Id',
		})

		columns.push({
			data: 'amount',
			title: 'Amount',
			name: 'amount',
			orderable: true,
			render: CellRender.money(),
		})

		columns.push({
			data: 'status',
			title: 'Status',
			orderable: true,
			name: 'status',
			render: CellRender.status(),
		})

		columns.push({
			data: 'type',
			title: 'Type',
			orderable: true,
			name: 'type',
			render: CellRender.payment(),
		})

		columns.push({
			data: 'narration',
			title: 'Narration',
			orderable: true,
			name: 'narration',
		})

		columns.push({
			data: 'created_at',
			name: 'created_at',
			title: 'Created',
			orderable: true,
			render: CellRender.date(),
		})
		columns.push({
			data: 'verified_at',
			name: 'verified_at',
			title: 'Verified',
			orderable: true,
			render: CellRender.date(),
		})

		columns.push(Column.actions(this.$gate, { update: false, view: false }))

		return columns
	}

	get options() {
		return {
			ajax: {
				url: this.route(`${this.model.model}.ajax.transactions.table`, { [this.model.model]: this.model.slug ?? this.model.id }),
				method: 'POST',
			},
			buttons: this.buttons,
			columns: this.columns,
		}
	}
}

export default toNative(TransactionTable)
</script>
